<template id="inventario">
<div class="page-inventario">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Inventario Actual" class="elevation-1 px-5 py-3">

        <v-card-actions>
            <v-spacer></v-spacer>
            <exportar-excel :data="inventario_exportar" :exportFields="encabezadoLayoutInventario" name="Inventario_Actual.xlsx">
                <v-btn id="botonExportar" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>&nbsp;
              <!--v-btn fab dark x-small color="green darken-2" title="Exportar Inventario Actual" @click="get_inventario()" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn-->
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">
              <v-row>
                <v-col sm="6" md="6" lg="6" align-self="center" justify="center">
                  
                  <v-checkbox v-model="mayores_que_cero" label="Mostrar solo existencia mayor a cero" 
                  @change="get_inventario()"
                  :true-value="1" :false-value="0"></v-checkbox>
                </v-col>
              </v-row>
              <v-client-table ref="vuetable" :data="inventario_exportar" :columns="columns" :options="options" 
              :key="keyTableInventario" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>

                <template slot="categoria" slot-scope="props">                  
                    {{props.row.categoria.join(", ")}}
                </template>

                <template slot="existencia" slot-scope="props">
                  <div style="text-align:center">
                    <v-chip x-small color="success" 
                        @click="get_detalle(props.row)">
                        <b>{{ formatNumberDecCero(props.row.existencia,2) }}</b>
                    </v-chip>                                    
                  </div>
                </template>

                <template slot="existencia_matriz" slot-scope="props">
                  <div style="text-align:center">
                    <b>{{ formatNumberDecCero(props.row.existencia_matriz,2) }}</b>                    
                  </div>
                </template>

                <template slot="actions" slot-scope="props">

                <v-btn x-small fab dark color="green" @click.native="get_detalle(props.row)" title="Ver Detalle" v-tippy>
                      <v-icon>list</v-icon>
                    </v-btn>
                </template>
              </v-client-table>              
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modal_detalle" max-width="60%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>Detalle de Artículo: {{model_editable.nombre}}</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal_detalle = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col>
              <v-client-table ref="vuetablearticulos" :data="lote_detalle" :columns="columns_detalle" :options="options_detalle" class="elevation-2 pa-2">
                <template slot="id_sucursal" slot-scope="props">
                  {{get_sucursal(props.row.id_sucursal)}}
                </template>

                <template slot="fecha_caducidad" slot-scope="props">
                  <v-chip x-small color="gray" text-color="black" title="Cambiar Fecha" 
                      v-tippy @click="cambiarfecha(props.row)">
                      <b>{{props.row.fecha_caducidad!=""?props.row.fecha_caducidad.toString().substring(0,10):"N/A"}}</b>
                  </v-chip>                  
                </template>

                <template slot="fecha_entrada" slot-scope="props">
                  {{props.row.fecha_entrada.toString().substring(0,10)}}
                </template>

                <template slot="cantidad" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.cantidad).toFixed(2)}}
                  </div>
                </template>

                <template slot="precio_compra" slot-scope="props">
                  <div style="text-align: center;">
                    ${{props.row.precio_compra}}
                  </div>
                </template>

                <template slot="actual" slot-scope="props">
                  <div style="text-align: center;">                                      
                      {{ parseFloat(props.row.actual).toFixed(2) }}                    
                  </div>
                </template>

                <template slot="comprometido" slot-scope="props">
                  <div style="text-align: center;">
                    {{parseFloat(props.row.comprometido).toFixed(2)}}
                  </div>
                </template>

              </v-client-table>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_detalle=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- modal fecha -->
    <v-dialog v-model="modalfecha" max-width="25%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Fecha Caducidad</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalfecha = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">
                                <v-menu offset-x ref="menu_fecha" v-model="menu_fecha" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                  <template v-slot:activator="{ on }">
                                    <v-text-field v-on="on" v-model="fecha_editable" label="Fecha" color="secondary" prepend-icon="event"
                                      ref="ingresafecha" readonly
                                     @blur="fecha_editable = parseDate(fecha_editable)" :rules="[rules.required]">
                                    </v-text-field>
                                  </template>

                                  <v-date-picker ref="ingresafecha" v-model="fecha_editable" color="secondary" scrollable locale="es-mx" @input="menu_fecha = false">
                                    <v-spacer />

                                    <v-btn color="secondary" x-small @click="menu_fecha = false">
                                      Cancelar
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalfecha = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="guardarfecha()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                                                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- modal cantidad -->
    <v-dialog v-model="modalactual" max-width="25%">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">edit</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>Actualizar Cantidad Actual</strong>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalactual = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="formcantidad" lazy-validation>
                    <v-container>
                        <v-row justify="center">
                            <v-col sm="12" md="12" lg="12" style="text-align:left !important;">

                                <v-text-field label="Actual" v-model="actual_editable" ref="ingresacantidad" 
                                v-on:keydown.enter.prevent='1' @keyup.enter="guardaractual()"
                                :rules="[validaCantidadFloat]"></v-text-field>

                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            &nbsp;
                        </v-row>
                        <v-row justify="center">
                            <v-btn small @click.native="modalactual = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn small color="success" @click.native="guardaractual()">
                                <v-icon>done</v-icon> Guardar
                            </v-btn>
                                                        
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>

</div>
</template>


<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';

export default {
  components: {
    "PaginacionComponent": PaginacionComponent
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    }
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('s.inventarios.actual')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
    this.get_sucursales();    
  },
  data() {
    return {
      sucursales:[],
      existencias_matriz:[],
      suc_matriz: "",
      keyTableInventario:"table_inv",
      menu_fecha:false,
      fecha_editable:"",
      actual_editable:"",
      model_editable:"",
      modalfecha:false,
      modalactual:false,
      sucursal_activa:"",
      mayores_que_cero:0,
      modal_detalle: false,
      lote_detalle: [],
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          disabled: true,
          href: ""
        },
        {
          text: "Actual",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "codigo_barras",
        "nombre",
        "descripcion",
        "estatus",
        "categoria",
        "marca",
        "existencia",
        "existencia_matriz"        
      ],
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["codigo_barras", "nombre", "descripcion","estatus","categoria", "marca","existencia","existencia_matriz"],
        sortable: ["codigo_barras", "nombre", "descripcion","estatus","categoria", "marca","existencia","existencia_matriz"],

        headings: {
          codigo_barras: "Código de Barras",
          nombre: "Nombre",
          descripcion: "Descripción",
          "categoria":"Categoría",
          "marca": "Marca/Modelo",
          "existencia": "Existencia en Sucursal",
          "existencia_matriz": "Existencia en Matriz",          
        },
      },

      columns_detalle: ["id_sucursal", "cantidad", "precio_compra", "fecha_caducidad", "fecha_entrada", "actual", "comprometido"],
      options_detalle: {
        filterable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],
        sortable: ["id_sucursal", "cantidad", "precio_compra", "actual", "comprometido"],

        headings: {
          id_sucursal: "Sucursal",
          cantidad: "Cantidad",
          precio_compra: "Precio de Compra",
          fecha_caducidad: "Fecha de Caducidad",
          fecha_entrada: "Fecha de Entrada",
          actual: "Actual",
          comprometido: "Comprometido"
        },
      },
      
      modal: false,
      items: [],
      articulos:[],
      articulos_all:[],
      inventario_exportar: [],
      encabezadoLayoutInventario: {
            codigo_barras: "codigo_barras",
            nombre:"nombre",
            descripcion:"descripcion",
            estatus:"estatus",
            marca:"marca",
            categoria:"categoria.0",
            "Existencia en Sucursal": "existencia",
            "Existencia Matriz": "existencia_matriz"
        },
      camposLayoutInventario: [{
            codigo_barras: "[Código de Barras del artículo]",
            nombre:"[Nombre del articulo]",
            descripcion:"[Descripción del articulo]",
            categoria:"[Categorías]",
            marca:"[Marca/Modelo]",
            existencia: "[Existencia Actual en la sucursal]"
        }],
    }
  },
  methods: {
    infiniteHandler: function($state) {
      let sucursal = this.sucursal_activa;
      var rows_per_page = 10;
      var page = Math.ceil(this.articulos.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      let where = {"type":"articulos"};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if(x == "codigo_barras"){
            where[x] = {
              "$in": [this.model_filters[x]]
            }
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }          
        }
      });


      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where
          },
        }).then(async response => {
          if (response.data.docs.length) {
            
              await Promise.all(response.data.docs.map(async (x) => {
                x.total_inventario = 0;
                x.inventariado_en_sucursal = 0; //Para saber si ese articulo tiene lotes en la sucursal, sino no se muestra en la lista
                let datos_consulta = {
                  "id_articulo": x._id,
                  "id_sucursal": sucursal == 0 ? "" : sucursal
                };

                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                  if (result.length > 0) {
                    x.inventariado_en_sucursal = 1;                    
                    result.forEach(el => {
                      x.total_inventario += el.existencia
                    });
                  } else {
                    x.inventariado_en_sucursal = 0;
                  }

                }).catch(err => {
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar existencia de artículo.",
                    footer: err
                  });
                });
              }));
              
            
            if(this.mayores_que_cero == 1){
              var filter = response.data.docs.filter(e => e.inventariado_en_sucursal==1 && e.total_inventario > 0);  
            } else {
              var filter = response.data.docs.filter(e => e.inventariado_en_sucursal==1);
            }            
            var unique_records = new Set(this.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());            
            var nuevos = filter.filter(e => !unique_records.includes(e._id));
            this.items = this.items.concat(nuevos);

            //this.items = this.items.concat(filter);//Solo se muestran los articulos que tienen lotes en la sucursal
            this.articulos = this.articulos.concat(response.data.docs);            
            this.props_paginacion.total_registros = this.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    get_sucursales: function () {
        window.dialogLoader.show('Espere un momento por favor..');
        let data = {
            "selector": {
                "nombre":{"$exists":true}
            },
            "fields": [
                "nombre", "_id","abastos","matriz"
            ],
            "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];                
                this.suc_matriz = "";
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;
                    for(var k in this.sucursales){                        
                        if(this.sucursales[k].matriz == 1){
                            this.suc_matriz = this.sucursales[k];                            
                        }
                    }

                }

                if(this.suc_matriz == ""){
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "No se ha configurado en Sucursales la sucursal Matriz. Es necesario para el módulo de Inventario.",
                        footer: ""
                    }).then(()=>{
                        this.goHome();
                    });
                } else {
                  this.get_inventario();
                }
                
            }).catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
                window.dialogLoader.hide();
            });
    },

    parseDate(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },

    cambiarfecha: async function (row) {
        this.fecha_editable = this.parseDate(row.fecha_caducidad);

        window.dialogLoader.show('Espere un momento por favor..');
        await window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {

                this.model_editable = response.data;
                this.modalfecha = true;                
            })
            .catch(error => {
                console.log(error);                
            }).then(()=>{
              window.dialogLoader.hide();
            });
    },

    guardarfecha: function(){
      if(this.$refs.formcantidad.validate()){        
          window.dialogLoader.show('Espere un momento por favor..');
            this.model_editable.fecha_caducidad = window.moment(this.fecha_editable).format("YYYY-MM-DDTHH:mm:ss");
            window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_editable._id + '?conflicts=true', this.model_editable)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
              this.modalfecha = false;
              this.modal_detalle = false;
              this.get_detalle(this.model_editable);
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
                color: 'error'
              });
            }).then(() => {              
              window.dialogLoader.hide();
            });
      }      
    },

    modal_actual: function(row){
      this.actual_editable = parseFloat(parseFloat(row.existencia).toFixed(2));
      this.model_editable = row;
      this.modalactual = true;
      setTimeout(() => {
          this.$nextTick(() => this.$refs.ingresacantidad.focus());
      }, 100);             
    },

    cambiaractual: async function (row) {
        this.actual_editable = parseFloat(parseFloat(row.actual).toFixed(2));

        window.dialogLoader.show('Espere un momento por favor..');
        await window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                this.model_editable = response.data;
                this.modalactual = true;   
                setTimeout(() => {
                  this.$nextTick(() => this.$refs.ingresacantidad.focus());
              }, 100);             
            })
            .catch(error => {
                console.log(error);                
            }).then(()=>{
              window.dialogLoader.hide();
            });
    },

    guardaractual: async function(){
      if(this.$refs.formcantidad.validate()){        
          window.dialogLoader.show('Espere un momento por favor..');
            //Traerse los lotes, y hacer el ajuste necesario a cada uno para dejar la cantidad ingresada
            var cantidad_final = parseFloat(parseFloat(this.actual_editable).toFixed(2));
            
            let datos_consulta = {
              "type":"lotes",
              "id_articulo": this.model_editable.id_articulo,
              "id_sucursal": this.sucursal_activa,
              "$or": [
                {"actual": {"$gt": 0}}, {"actual": {"$lt": 0}} //Diferente de cero
              ]
            };
            
            axios({
                method: 'POST',
                url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
                withCredentials: true,
                data: {selector: datos_consulta},
              }).then(async response => {
                if (response.data.docs.length) {
                  var bulk_lotes = [];
                    var lotes = response.data.docs;
                    lotes.sort(function (a, b) {//Ordenar por fecha más reciente                        
                        return new Date(b.fecha_entrada) - new Date(a.fecha_entrada);
                    });

                    //Sacamos el total
                    var existencia_total = lotes.reduce(function(total, lote) {
                        return total + parseFloat(lote.actual);
                    }, 0);

                    //Si la existencia es mas grande a la cantidad_final, se ponen todos los lotes en cero, y al mas reciente se deja la cantidad_final
                    if (existencia_total > cantidad_final) {
                      for(var i in lotes){
                        if(i == 0){
                          lotes[i].actual = cantidad_final;
                        } else {
                          lotes[i].actual = 0;
                        }
                        bulk_lotes.push(lotes[i]);
                      }
                    }
                    //Si la existencia es menor a la cantidad_final, se acompleta en el lote mas reciente el faltante para llegar a la cantidad_final 
                    else if (existencia_total < cantidad_final) {
                      var faltante = cantidad_final - existencia_total;
                      //El faltante se agrega al lote mas reciente
                      lotes[0].actual += faltante;
                      bulk_lotes.push(lotes[0]);                      
                    } 

                    if(bulk_lotes.length>0){
                      await this.actualizar_lotes(bulk_lotes, cantidad_final);                      
                    } else {
                      this.modalactual = false;
                      window.dialogLoader.hide();
                    }
                  
                } else {//Si no hay lotes se da uno nuevo de alta con la cantidad
                  let data = {
                    id_sucursal: this.sucursal_activa,  //Requerido
                    id_articulo: this.model_editable.id_articulo,  //Requerido
                    id_compra: null,      //Requerido
                    cantidad: cantidad_final, //Requerido
                    precio_compra: parseFloat(this.model_editable.precio_compra), //Requerido
                    fecha_caducidad: null, //Opcional
                    fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"),  //Requerido
                    origen: "inventario actual",
                    usuario: this.$local_storage.get("sb_usuario"),

                  };
                  //console.log(data);

                  window.funciones_lotes.nuevaEntradaInventario(data).then(result =>{
                      window.dialogLoader.showSnackbar('El registro se guardó correctamente..', {
                        color: 'success'
                      });
                      var articulo = this.inventario_exportar.find(e => e.id_articulo == this.model_editable.id_articulo);
                      if(articulo){
                        articulo["existencia"] = cantidad_final;
                      }                      
                      this.refresh_tabla();
                      
                  }).catch(err => {
                          this.$swal({
                              type: "error",
                              title: "¡Operación no Permitida!",
                              text: "Ocurrió un error al guardar el registro.",
                              footer: ""
                          });
                          console.log(err);
                  }).then(() => {
                      this.modalactual = false;
                      window.dialogLoader.hide();
                    });
                  
                }
              })
              .catch(error => {     
                this.modalactual = false;           
                window.dialogLoader.hide();
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al consultar lotes. Intente Nuevamente.",
                  footer: ""
                });
              })
      }      
    },

    refresh_tabla: function(){
      this.keyTableInventario = moment(new Date()).format("x"); //Refrescar tabla
    },
    
    actualizar_lotes:function(lotes, cantidad_final){
      var self = this;
      var docs = {
        "docs": lotes
      };
      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_bulk_docs', docs)
        .then(response => {

          var lotes_no_afectados = [];
          var registros_con_error = [];

          for(var k in response.data){
              var res = response.data[k];
              if (res.id && res.error && res.error == "conflict"){ //Si el trae conflict significa que hubo algun error o conflicto, se manda de nuevo
                  registros_con_error.push(res.id);
              }
          }
          
          if (registros_con_error.length > 0){
              //los no afectados volverlos a enviar
              for(var kl in lotes){
                  if (registros_con_error.includes(lotes[kl]["_id"])){
                      lotes_no_afectados.push(lotes[kl])
                  }                                    
              }                                              
          }

          if (lotes_no_afectados.length > 0){   
              self.actualizar_lotes(lotes_no_afectados, cantidad_final);              
          } else {
            window.dialogLoader.showSnackbar('El registro se guardó correctamente..', {
              color: 'success'
            });   
            var articulo = this.inventario_exportar.find(e => e.id_articulo == this.model_editable.id_articulo);
            if(articulo){
              articulo["existencia"] = cantidad_final;
            }       
            this.modalactual = false;
            this.refresh_tabla();
          }

        })
        .catch(error => {
          self.$swal({
            icon: "warning",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al guardar. Intente nuevamente.",
            footer: "",
          });          
        }).then(()=>{
          window.dialogLoader.hide();
        });
    },

    get_inventario: function() {
      window.dialogLoader.show('Espere un momento por favor..');
      var self = this;
      
      var urlConditions = "&startkey=[\""+this.sucursal_activa+"\"]&endkey=[\""+this.sucursal_activa+"\",{}]";
      this.inventario_exportar = [];
      let where = {
        "type": "articulos",
      };
      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          if(x == "codigo_barras"){
            where[x] = {
              "$in": [this.model_filters[x]]
            }
          } else {
            where[x] = {
              "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
            }
          }          
        }
      });

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: where,   
            "fields":["_id","codigo_barras","nombre","descripcion","marca","precio_compra_con_iva","categoria","estatus"]
          },
        }).then(async response => {
          if (response.data.docs.length) {
              self.articulos_all = response.data.docs;
              
              await window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_total_sucursal?group_level=2'+urlConditions)
              .then(response => {
                  if(response.data!=null && response.data.rows!=null){
                     
                      response.data.rows.forEach(element => {
                          var art = self.validaArticuloImport(element.key[1]);                          
                          if(art != ""){                            
                            self.inventario_exportar.push(
                              {"id_articulo": art["_id"],
                              "codigo_barras": art["codigo_barras"][0],
                              "nombre": art["nombre"],
                              "estatus": art["estatus"],
                              "descripcion":art["descripcion"],
                              "marca": art["marca"],
                              "categoria": art["categoria"],
                              "precio_compra": art["precio_compra_con_iva"],
                              "existencia": parseFloat(element.value.toFixed(2)),
                              "existencia_matriz": self.sucursal_activa == self.suc_matriz["_id"] ? parseFloat(element.value.toFixed(2)) : 0
                            });
                              
                          }                          
                      });  
                      
                      if(self.mayores_que_cero == 1){
                        self.inventario_exportar = self.inventario_exportar.filter(e => e.existencia > 0);  
                      } 

                      self.get_inventario_matriz();
                  } else {
                    window.dialogLoader.hide();
                  }
              }).catch(err => {
                  self.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las existencias de Matriz. Intente nuevamente.",
                    footer: "",
                  });          
                  window.dialogLoader.hide();
              });
          }
        }).catch(error => {
          console.log(error);
          self.$swal({
            type: "warning",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los artículos. Intente nuevamente.",
            footer: "",
          });          
          window.dialogLoader.hide();
        });    

      
    },

    get_inventario_matriz: function() {
      window.dialogLoader.show('Espere un momento por favor..');
      var self = this;
      
      if (this.sucursal_activa != this.suc_matriz._id){//Solo si la sucursal es diferente de matriz, se busca la existencia matriz
        
        var urlConditions = "&startkey=[\""+this.suc_matriz._id+"\"]&endkey=[\""+this.suc_matriz._id+"\",{}]";        
                
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/lotes/_view/existencia_total_sucursal?group_level=2'+urlConditions)
        .then(response => {
            if(response.data!=null && response.data.rows!=null){
              
                response.data.rows.forEach(element => {
                    var art = self.inventario_exportar.find(e => e.id_articulo == element.key[1]);
                    if(art){
                      art["existencia_matriz"] = parseFloat(element.value.toFixed(2));
                    }                          
                });                  
            }
        })
        .catch(err => {
            console.log(err);
        }).then(()=>{
          /* var btn = document.getElementById('botonExportar');
          if(btn){
            btn.click();
          } */
          window.dialogLoader.hide();
        });
            
      } else { 
        window.dialogLoader.hide();
      }
      
    },
    validaArticuloImport: function(id_articulo) {
      var filter = this.articulos_all.find(e => {
        return e._id == id_articulo;
      });
      
      if (filter)
        return filter;
      else
        return "";
    },
    
    get_detalle: function(model, row=null) {
      var self = this;      
      var id_articulo = model.id_articulo;
      window.dialogLoader.show('Espere un momento por favor..');
      let datos_consulta = {
        //"type":"lotes",
        "id_articulo": id_articulo,        
        //"$or": [{"actual": {"$gte": 0}}, {"actual": {"$lt": 0}}, {"comprometido": {"$lt": 0}}]        
      };
      if (this.sucursal_activa != 0) {
        datos_consulta.id_sucursal = this.sucursal_activa;
      }

      axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            selector: datos_consulta,
            "use_index":"_design/1057e79fff87779ca3a3c6b1708bbe5d9381a867" 
          },
        }).then(async response => {
          if (response.data.docs.length) {
            this.lote_detalle = response.data.docs;
            //this.lote_detalle = this.lote_detalle.filter(e => e.actual != 0 || e.comprometido < 0);
            this.lote_detalle.sort(function (a, b) {//Ordenar por fecha más reciente
                return new Date(b.fecha_entrada) - new Date(a.fecha_entrada);
            });
            //actualizamos la existencia en la tabla principal
            var art = self.inventario_exportar.find(e => e.id_articulo == id_articulo);
            if(art){
              var existencia_total = this.lote_detalle.reduce(function(total, lote) {
                        return total + parseFloat(lote.actual);
                    }, 0);
              art["existencia"] = parseFloat(existencia_total.toFixed(2));
            }

            if(row == null){
              this.modal_detalle = true;
              this.model_editable = model;
            } else {//Si trae row quiere decir que se va a editar la existencia
              this.actual_editable = parseFloat(parseFloat(art["existencia"]).toFixed(2));
              this.model_editable = row;
              this.modalactual = true;
              setTimeout(() => {
                  this.$nextTick(() => this.$refs.ingresacantidad.focus());
              }, 100);             
            }
            
          } else {
            this.lote_detalle = [];
            if(row == null){
              this.modal_detalle = true;
            } else {
              this.actual_editable = parseFloat(parseFloat(row.existencia).toFixed(2));
              this.model_editable = row;
              this.modalactual = true;
              setTimeout(() => {
                  this.$nextTick(() => this.$refs.ingresacantidad.focus());
              }, 100);             
            }
          }
        })
        .catch(error => {
          this.modal_detalle = false;
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al consultar el detalle del artículo. Intente nuevamente",
            footer: "",
          });
        }).then(()=>{
          window.dialogLoader.hide();
        })
    },
    get_sucursal: function(id) {
      let seleccionada = window.sucursales.filter(x => x._id == id);

      return seleccionada.length > 0 ? seleccionada[0].nombre : "No Encontrada";
    },
    validaCantidadFloat: function (value) {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          if (value != null && value != "" && !pattern.test(value)) {
              return "Cantidad no válida";
          } else if (value.toString().trim() == ""){
            return "Cantidad no válida";
          }
          return true;
      },
    formatNumberDecCero: function (numero, decimales) {
        if (numero === undefined || numero === null || numero === "") {
            numero = 0;
        }

        numero = Number(numero);
        if (isNaN(numero)) {
            return "";
        }

        // Determinar si el número tiene decimales
        if (Number.isInteger(numero)) {
            // Número entero, formatear sin decimales
            return numero.toLocaleString("en-US");
        } else {
            // Número con decimales, formatear según los decimales especificados
            return numero.toFixed(decimales).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
  }
}
</script>
